@import '~@which/seatbelt/src/styles/styles';
@import '../../PersonalisedMemberBenefits.module.scss';

.panelContent {
  position: relative;
  overflow: hidden;
}

.panel {
  padding-bottom: 0px;
  position: relative;
}

.strikedPrice {
  color: $sb-colour-text-default;
  text-decoration: line-through;
}

.benefitContent {
  width: 100%;
  margin-top: $sb-spacing-m;
}

.headingContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  row-gap: $sb-spacing-s;
  margin-bottom: $sb-spacing-m;

  &Subheading {
    color: $sb-colour-background-brand-red;
  }
}
