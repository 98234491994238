@import '../../PersonalisedMemberBenefits.module.scss';

.panelContent {
  margin-bottom: $sb-spacing-s;
}

.benefitContent {
  min-height: 390px;
}

.headingContainer {
  padding-left: $sb-spacing-s;
  padding-top: $sb-spacing-s;
}

.signupButtonLink {
  [class*='SignupButton_caption'] {
    padding-top: $sb-spacing-s;
  }
}
